@import-normalize;

*:focus {
  outline: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  overflow: hidden;
  font-size: 12px;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 11px;
  }
}
